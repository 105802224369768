<template>
    <div v-if="isLoggedIn == true" id="vendor_variants" class="container_big">
      <div v-if="loader">
        <ProgressSpinner id="loader" />
      </div>
     <div class="inner">
            <h1>{{ vendor }}</h1>
        <div v-if="variants && variants.length > 0" class="variants_table_col"> 
            <DataTable :value="variants" ref="dt" :paginator="true" :rows="50"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                responsiveLayout="scroll"
                :exportFilename=$route.params.vendor
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                v-model:filters="filters1" 
                :loading="loader"
                scrollable scrollHeight="1000px"
                :globalFilterFields="['displayName','sku','legacyResourceId','product.legacyResourceId','metafield.legacyResourceId']"
                 editMode="row" dataKey="sku" v-model:editingRows="editingRows" @row-edit-save="onRowEditSave"
                >
                <template #header>
                    <div class="left" style="text-align: left">
                        <Button icon="pi pi-external-link" label="Export to CSV" @click="exportToCsv"></Button>
                    </div>
                    <div class="right" style="text-align: right">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input v-model="filters1['global'].value" placeholder="Keyword Search" />
                        </span>
                    </div>
                </template>
                <template #loading>
                    Loading variants data. Please wait.
                </template>
                <Column v-for="col of columns" :field="col.field" :filterField="col.header" :header="col.header" :key="col.field">
                </Column> 
                
                <Column field="metafield.legacyResourceId" header="Metafield Id" class="meta_id" >
                  <template #body="slotProps">
                    {{ !slotProps.data.metafield?false:slotProps.data.metafield.legacyResourceId }}
                  </template>
                </Column>
                <Column field="metafield.value" header="Date" class="meta_date" :editable="true">
                    <template #editor="{ data, field }">
                        <Calendar v-model="data[field]" :default-date="data.metafield.value" :placeholder="data.metafield.value" dateFormat="MM dd, yy" :minDate="new Date()" showIcon required/>
                        <Button v-if="data.metafield.value != 0" icon="pi pi-trash" outlined rounded severity="danger" class="clearBtn" @click="clearMetafield($event,data.metafield.legacyResourceId)"></Button>
                    </template>
                </Column>
                <Column :rowEditor="true" header="Update" class="edit_btn">Edit</Column>
            </DataTable>
        </div>
        <div v-else>
            <p v-if="APIerror" class="error">{{ APIerror }}</p>
        </div>
    </div>    
    <div v-bind:class="(loader)?'loading':'loaded'"></div> 
    </div>
    <div v-else class="error">
      Please login to check the page <a href="/">Login</a>
    </div>
</template>



<script>
  import { mapActions,mapGetters } from "vuex";
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import Button from 'primevue/button';
  import {FilterMatchMode,FilterOperator} from 'primevue/api';
  import ProgressSpinner from 'primevue/progressspinner';
  import Calendar from 'primevue/calendar';
  import Papa from 'papaparse';
  
  export default {
    name: 'VendorVariants',
     components: {
        DataTable,
        Column,
        Button,
        ProgressSpinner,
        Calendar
     },
      data() {
        return {
          editingRows: [],
          APIerror:'',
          loader:true,
          vendor:this.$route.params.vendor.replace(/-/g, ' '),
          total:Number,
          filters1: null,
          variants:[],
          edit:false
        }
     },
     created() {
      this.initFilters1();
      this.columns = [
            {field: 'product.legacyResourceId', header: 'Product ID'},
            {field: 'displayName', header: 'Title'},
            {field: 'legacyResourceId', header: 'Variant ID'},
            {field: 'sku', header: 'Variant Sku'},
            {field: 'inventoryQuantity', header: 'Ineventory'},
        ];
     },
      methods: {
           ...mapActions(["getVendorVariants","updateMetafield","clearMetafieldApi"]),
           ...mapGetters(["isAuthenticated"]), 
           exportCSV() {
             this.$refs.dt.exportCSV("name");
           },
           clearFilter1() {
            this.initFilters1();
           },
           initFilters1() {
            this.filters1 = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'displayName': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                    'sku': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                    'legacyResourceId':{operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                    'product.legacyResourceId':{operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                    'metafield.legacyResourceId':{operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}
                }
            },
            async onRowEditSave(event) {
              let { newData } = event;
              if(!newData["metafield.value"]){return}
                  this.loader = true;
                  const data = {
                    ...newData,
                    "token":this.userToken
                  }
                  const update =  await this.updateMetafield(data);
                  var objIndex = this.variants.findIndex((obj => obj.legacyResourceId == update.owner_id));
                  this.variants[objIndex].metafield.value = update.value;
                  this.loader = false;
          },
        async exportToCsv() {
           const data = this.variants.map((item) => {return {
            name:item.displayName,
            sku:item.sku,
            variantID:item.legacyResourceId,
            productID:item.product.legacyResourceId,
            metafieldID:item.metafield?.legacyResourceId?item.metafield.legacyResourceId:null,
            metafieldValue:item.metafield?.value?item.metafield.value:0
          } });
           const header = ['name', 'productID', 'sku',"variantID","metafieldID","metafieldValue"];
           const csvData = Papa.unparse({data:data,fields: header});
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', this.vendor+'.csv');
            document.body.appendChild(link);
            link.click();
          },
          editRow(rowData) {
            this.$refs.dt.toggleRow(rowData);
          },
          async clearMetafield(event,id){
            let row = event.target.parentElement.parentElement;
            let btnsTb = row.nextElementSibling;
            let rowCancelBtn = btnsTb.querySelectorAll(".p-row-editor-cancel");
              if(id){
                this.loader = true; 
                const data = {"id":id,"token":this.userToken};
                const update =  await this.clearMetafieldApi(data);
                var objIndex = this.variants.findIndex((obj => obj.legacyResourceId == update.owner_id));
                this.variants[objIndex].metafield.value = update.value;                
                if(rowCancelBtn[0]){
                  rowCancelBtn[0].click();
                }
                this.loader = false;
              }
          },
          cancelEdit() {
            // this.$refs.dt.exitEditMode();
          },
      },
      mounted:async function () {
        if(this.isLoggedIn){
            this.vendor = this.vendor.replace('&amp;','&');
            const data = {"token":this.userToken,"vendor":this.vendor};
            const variation =  await this.getVendorVariants(data);
            console.log("variation",variation);
            if(!variation){
                this.APIerror = "Something went Wrong!";
                this.loader = false;
                return;
            }
            else if(variation.status){
                this.APIerror = variation.message;
                this.loader = false;
                return;
            }
            this.variants = variation.variants;
            this.total = variation.length;    
            this.loader = false;
            console.log("this.variants",this.variants);
        }
      },
      computed: {
        isLoggedIn : function(){
            return this.$store.getters.isAuthenticated
        },
        userToken : function(){
          return this.$store.getters.getUserToken
        }
      },
  
  
  }
  </script>